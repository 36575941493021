import React from "react";
import About from "../components/About";

const AboutContainer = () => {
  return (
    <div>
      <About />
    </div>
  );
};

export default AboutContainer;
