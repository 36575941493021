import React from "react";
import Project from "../components/Project";

const ProjectContainer = () => {
  return (
    <div>
      <Project name="project" />
    </div>
  );
};

export default ProjectContainer;
